import React from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import FooterMenu from "./FooterMenu";
import FooterInfo from "./FooterInfo";
import Section from "../../sections/Section";

const Container = styled.div`
  margin-top: 50px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

const SectionWrapper = styled.div`
  overflow: hidden; // important
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
`;

const Left = styled.div`
  flex: 1;
  text-align: left;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: right;
  align-items: flex-end;
`;

const Logo = styled.a`
  > img {
    width: 200px;
  }
`;
const Address = styled.address`
  font-style: normal;
  padding-top: 20px;
  > strong {
    display: block;
    color: ${({ theme }) => theme.palette.text.white};
    padding-bottom: 5px;
    > span {
      display: block;
      font-size: 12px;
      font-weight: bold;
      color: ${({ theme }) => theme.palette.text.red};
    }
  }
`;

const Bottom = styled.div`
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.background.dark};
  color: ${({ theme }) => theme.palette.text.red};
`;

const Footer = (props) => {
  return (
    <Container>
      <SectionWrapper>
        <Section>
          <Wrapper>
            <Left>
              <FooterMenu />
            </Left>
            <Right>
              <Logo href="/">
                <img src="/logo.png" alt="Logo" />
              </Logo>
              <FooterInfo />
            </Right>
          </Wrapper>
          <Bottom>
            <div>
              <p>
                Copyright &copy; {new Date().getFullYear()} Diamantidou School |
                created by
                <a href="http://www.andreasodysseos.com">ao</a>
              </p>
            </div>
          </Bottom>
        </Section>
      </SectionWrapper>
    </Container>
  );
};

Footer.defaultProp = {};

Footer.propTypes = {};

export default Footer;
