import React from "react";
import styled from "styled-components";
import { Link } from "@material-ui/core";

import Button from "../../components/Button";

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    line-height: 2;
    a {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.palette.text.white};
      &:hover {
        color: ${({ theme }) => theme.palette.text.red};
        text-decoration: none;
      }
    }
  }
`;

const FooterMenu = (props) => {
  return (
    <Menu>
      <li>
        <Link href={`/blog`}>News</Link>
      </li>
      <li>
        <Link href={`/offers`}>Offers</Link>
      </li>
      <li>
        <Link href={`/contact`}>Contact</Link>
      </li>
    </Menu>
  );
};

FooterMenu.defaultProp = {};

FooterMenu.propTypes = {};

export default FooterMenu;
