import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const SectionWrapper = styled.section`
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? "initial" : "1140px")};
  padding: 2em 1em;
`;

const Title = styled(Typography)`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 1rem;
  text-decoration: none;
  padding: 0 0 0.4em 0;
  margin: 2rem 0 2rem 0;
  text-align: center;
  > span {
    width: 73px;
    height: 4px;
    margin: 8px auto 0;
    display: block;
    background-color: #ed4d3d;
  }
`;

const Section = ({ title, children, fullWidth }) => (
  <SectionWrapper fullWidth={fullWidth}>
    {title && (
      <Title variant="h2" color="textPrimary" component="h2">
        {title}
        <span />
      </Title>
    )}
    {children}
  </SectionWrapper>
);

export default Section;
