import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Button, IconButton } from "@material-ui/core";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
} from "@material-ui/icons";

const Navbar = styled.nav`
  position: relative;
  width: 100%;
  z-index: 9;

  > ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    > li {
      list-style: none;
      padding: 0 5px;
    }
  }
`;

const ButtonLink = styled(Button)`
  color: ${({ theme }) => theme.palette.text.red};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
    background-color: ${({ theme }) => theme.palette.text.red};
  }
  border-radius: 20px;
`;

const SocialLink = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.red};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
    background-color: ${({ theme }) => theme.palette.text.red};
  }
`;

const Navigation = (props) => {
  return (
    <Navbar>
      <ul>
        <li>
          <ButtonLink variant="primary" color="body" href={`/blog`}>
            News
          </ButtonLink>
        </li>
        <li>
          <ButtonLink variant="primary" color="body" href={`/contact`}>
            Contact
          </ButtonLink>
        </li>
        <li>
          <SocialLink
            aria-label="facebook"
            component="a"
            target="_blank"
            href={`https://www.facebook.com/diamantidouschoolofmusic.danceanddrama`}
          >
            <FacebookIcon />
          </SocialLink>
        </li>
        <li>
          <SocialLink
            aria-label="instagram"
            component="a"
            target="_blank"
            href={`https://www.instagram.com/diamantidou_school`}
          >
            <InstagramIcon />
          </SocialLink>
        </li>
      </ul>
    </Navbar>
  );
};

export default Navigation;
