import React from "react";
import { ThemeProvider } from "styled-components";
import NoSsr from "@material-ui/core/NoSsr";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";

import "./base.css";
import Container from "./container";
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#000",
      dark: "#000",
    },
    secondary: {
      light: "",
      main: "#000",
      dark: "",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      white: "#fff",
      red: "#ED4D3D",
      green: "#2DC462",
      blue: "#4285F3",
      yellow: "#FDE157",
      orange: "#F19A2A",
    },
    button: {
      primary: "#000",
      secondary: "#ED4D3D",
    },
    background: {
      default: "#fafafa",
      dark: "#000",
      paper: "#fff",
    },
    transparent: "transparent !important",
  },
});

const Layout = ({ children }) => {
  return (
    <NoSsr>
      <MuiThemeProvider theme={customTheme}>
        <ThemeProvider theme={customTheme}>
          <Container>
            <Header
              color="transparent"
              brand="Diamantidou School of Music, Dance & Drama"
              rightLinks={<Navbar />}
              fixed
              changeColorOnScroll={{
                height: 200,
                color: "black",
              }}
              // {...rest}
            />
            {children}
            <Footer />
          </Container>
        </ThemeProvider>
      </MuiThemeProvider>
    </NoSsr>
  );
};

export default Layout;
