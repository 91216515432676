import React from "react";
import styled from "styled-components";
import { Button, IconButton } from "@material-ui/core";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
} from "@material-ui/icons";

const Address = styled.address`
  font-style: normal;
  padding-top: 20px;
  > strong {
    display: block;
    color: ${({ theme }) => theme.palette.text.white};
    padding-bottom: 5px;
    > span {
      display: block;
      font-size: 12px;
      font-weight: bold;
      color: ${({ theme }) => theme.palette.text.red};
    }
  }
`;

const Telephone = styled.div`
  > a {
    color: ${({ theme }) => theme.palette.text.white};
  }
  > span {
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 0 5px;
  }
`;

const Email = styled.div`
  > a {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const Social = styled.div`
  display: flex;
  flex-direction: row;
`;

const SocialLink = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.red};
  &:hover {
    color: ${({ theme }) => theme.palette.text.white};
  }
`;

const FooterInfo = (props) => {
  return (
    <>
      <Address data-wow-duration="1s" data-wow-delay=".3s">
        <strong>
          21st Ilision Street P.O 3056, Agios Spiridonas
          <span>Main office</span>
        </strong>
        <strong>
          Edesis, 3a, P.O 4042, Germasogeia Municipality <span>Branch</span>
        </strong>
        <strong>Limassol, Cyprus.</strong>
        <Telephone>
          <a href="tel:+35725392563">25 392563</a>
          <span>/</span>
          <a href="tel:+35799494584">99 494584</a>
        </Telephone>
        <Email>
          <a href="mailto:m.diamantidou@hotmail.com">
            m.diamantidou@hotmail.com
          </a>
        </Email>
      </Address>
      <Social>
        <SocialLink
          aria-label="facebook"
          component="a"
          target="_blank"
          href={`https://www.facebook.com/diamantidouschoolofmusic.danceanddrama`}
        >
          <FacebookIcon />
        </SocialLink>
        <SocialLink
          aria-label="instagram"
          component="a"
          target="_blank"
          href={`https://www.instagram.com/diamantidou_school`}
        >
          <InstagramIcon />
        </SocialLink>
      </Social>
    </>
  );
};

FooterInfo.defaultProp = {};

FooterInfo.propTypes = {};

export default FooterInfo;
